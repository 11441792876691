import { Route, Routes } from "react-router-dom";
import NavbarComponent from "./components/Nav/Navbar";
import FooterComponent from "./components/Footer/Footer";
import ToggleDemoComponent from "./components/ToggleDemo/ToggleDemo";
import ListDemoComponent from "./components/ListDemo/ListDemo";
import StylesDemoComponent from "./components/StylesDemo/StylesDemo";
import ChatDemoComponent from "./components/ChatDemo/ChatDemo";
import WelcomeComponent from "./components/Welcome/Welcome";
import ResourceDemoComponent from "./components/ResourceDemo/ResourceDemo";
import "./app.css";

function App() {
  return (
    <div className="app">
      <NavbarComponent />
      <Routes>
        <Route path="/" element={<WelcomeComponent />} />
        <Route
          path="/element-visibility/"
          a
          element={<ToggleDemoComponent />}
        />
        <Route path="/list-building/" element={<ListDemoComponent />} />
        <Route path="/style-manipulation/" element={<StylesDemoComponent />} />
        <Route path="/chat-with-seb/" element={<ChatDemoComponent />} />
        <Route path="/resource-rally/" element={<ResourceDemoComponent />} />
      </Routes>
      <FooterComponent />
    </div>
  );
}

export default App;
